import { useCloseSelectBox } from "hooks";
import React, { useEffect, useState } from "react";
import { useGlobalStore } from "store/GlobalStore";
import Loader from "react-loader-spinner";

function Currency() {
  const [openCurrency, setOpenCurrency] = useState(false);
  const [wrapperRef] = useCloseSelectBox(setOpenCurrency);
  const [currencyType, setCurrencyType] = useGlobalStore((state) => [state.currencyType, state.setCurrencyType]);

  const [currencyText, setCurrencyText] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const onSetCurrencyType = (value) => {
    setOpenCurrency(!openCurrency);
    setCurrencyType(value);
  };

  useEffect(() => {
    setCurrencyText(currencyType === "₺" ? " ₺ TRY" : (currencyType === "$" ? "$ USD" : "€ EUR"));
    setIsLoading(false);
  }, [currencyType]);

  return (
    <div className="relative inline-block text-left" ref={wrapperRef}>
      <div>
        <button type="button" onClick={() => setOpenCurrency(!openCurrency)}
          className="z-50 inline-flex items-center justify-center w-full h-10 px-3 py-1 text-sm font-medium text-gray-700 bg-white border-2 rounded-lg border-makdosSecondaryGray hover:bg-gray-50 focus:outline-none"
          id="options-menu-currency" aria-haspopup="true" aria-expanded="true">
          {isLoading ?
            <div className="flex flex-col items-center justify-center">
              <Loader
                type="Oval"
                color="#FFDD00"
                height={10}
                width={10}
                timeout={1000}
              />
            </div> : currencyText}
          <i className="ri-arrow-down-s-line"></i>
        </button>
      </div>
      {openCurrency &&
        <div className="absolute left-0 z-50 w-32 mt-2 origin-top-left bg-white rounded-lg shadow-lg ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button type="button" name="₺" onClick={(e) => onSetCurrencyType(e.target.name)}
              className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border-b-2 border-makdosSecondaryGray hover:bg-gray-50 focus:outline-none"
              id="options-menu1" aria-haspopup="true" aria-expanded="true">
              ₺ TRY
            </button>
            <button type="button" name="$" onClick={(e) => onSetCurrencyType(e.target.name)}
              className="headerCurrency"
              id="options-menu2" aria-haspopup="true" aria-expanded="true">
              $ USD
            </button>
            <button type="button" name="€" onClick={(e) => onSetCurrencyType(e.target.name)}
              className="headerCurrency"
              id="options-menu3" aria-haspopup="true" aria-expanded="true">
              € EUR
            </button>
          </div>
        </div>
      }

    </div>
  );
}

export default Currency;
