import React from "react";
import { useWindowSize } from "hooks";
import Language from "components/TopHeader/Language";
import Currency from "components/TopHeader/Currency";
import { useTranslation } from "next-i18next";

function TopHeader() {
  const windowSize = useWindowSize();
  const { t } = useTranslation(["common", "nav"]);

  return (
    <>
      <div className="w-full bg-white">
        <div className="container z-50 justify-between py-1 mx-auto bg-white flexCenter">
          <div className="flex">
            <Language />
            <div className="ml-2">
              <Currency />
            </div>
          </div>

          <ul className="flex mr-2 space-x-2 lg:mr-0 lg:space-x-5">
            <li className="flexCenter">
              <i className=" text-textGray ri-headphone-line"></i>
              <a className="mt-0.5 ml-1 text-xs text-textGray" href="tel:+908503077744" target="_blank">0850 307 77 44</a>
            </li>
            <li className="flexCenter">
              <i className=" text-textGray ri-mail-line"></i>
              <a className="ml-1 text-xs text-textGray" href="mailto:info@makdos.com" target="_blank">{t("common:contact")}</a>
            </li>

            <li className="items-center hidden lg:flex">
              <i className=" text-textGray ri-edit-line"></i>

              <a className="ml-1 text-xs text-textGray" href="https://makdos.blog/" target="_blank">Blog</a>
            </li>
          </ul>
        </div>
        <div className="w-full border border-gray-100">
        </div>
      </div>

    </>
  );
}

export default TopHeader;
