import React, { useEffect, useRef, useState } from "react";
import SimpleTextBox from "components/FormElements/SimpleTextBox";
import IconTextBox from "components/FormElements/IconTextBox";
import Button from "components/FormElements/Button";
import SecondaryButton from "components/FormElements/SecondaryButton";
import Link from "next/link";
import {
  beforeLogin,
  beforeLoginCheck,
  checkLoginMail,
  forgotPasswordSendCode,
  getBasketCount,
  getCountry,
  login
} from "services/allServices";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthStore } from "store/AuthStore";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import { useConfigurationStore } from "store/ConfigurationStore";
import { useConfigureSelectBoxItem, useWindowSize } from "hooks";
import { useTranslation } from "next-i18next";
import PhoneSelectBox from "components/FormElements/PhoneSelectBox";
import moment from "moment";
import Validation from "../Validation";
import CryptoJS from "crypto-js";
import { useRouter } from "next/router";
import { alertToast } from "../Toast/AllToast";
import { useGlobalStore } from "store/GlobalStore";

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required()
});

function SignIn({ setOpenNewPasswordModal }) {
  const { t } = useTranslation(["common", "nav"]);
  const router = useRouter();
  const [isLoggin, setIsLoggin, setLogginOpen, setUserInformations, beforeLogginStatus, setBeforeLogginStatus, beforeLogginInfo, setBeforeLogginInfo] = useAuthStore((state) => [
    state.isLoggin,
    state.setIsLoggin,
    state.setLogginOpen,
    state.setUserInformations,
    state.beforeLogginStatus,
    state.setBeforeLogginStatus,
    state.beforeLogginInfo,
    state.setBeforeLogginInfo
  ]);
  const windowSize = useWindowSize();
  const [setPermissions] = useGlobalStore((state) => [state.setPermissions]);

  const [setBasketCount] = useConfigurationStore(((state) => [state.setBasketCount]));
  const [displayDanger, setDisplayDanger] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const [passwordReset, setPasswordReset] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneList, setPhoneList] = useConfigureSelectBoxItem();
  const [selectedPhone, setSelectedPhone] = useState();
  const [isSendMail, setIsSendMail] = useState("1");
  const [openTfaView, setOpenTfaView] = useState(false);
  const [loginBanCount, setLoginBanCount] = useState(5);
  const [otp, setOtp] = useState("");

  const encryptMessage = (message) => {
    let secretKey = process.env.NEXT_PUBLIC_CRYPTO_KEY;
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(message), secretKey);
    return cipherText.toString();
  };


  const onBeforeLogin = (data) => {
    let config = {
      grant_type: "password",
      scope: "",
      client_id: "",
      client_secret: "",
      username: data.email,
      password: data.password
    };

    let beforeConfig = {
      email: data.email,
      password: data.password
    };

    checkLoginMail(data.email).then((res) => {

      beforeLogin(beforeConfig).then((res) => {
        if (res?.status !== "password_is_wrong" && res?.status !== "incorrect_username_or_password" && res?.status !== "check_login_info") {
          if (res?.status === "code_sent") {
            setOpenTfaView(res);
            setLoginBanCount(5);
            setDisplayDanger(false);
          }
          if (res?.status === false) {
            setDisplayDanger(res.message);
          }

          if (res?.status === "code_not_sent") {
            setDisplayDanger("Kod gönderilemedi.");
          }

          if (res?.status === "tfa_disabled") {
            if (res?.authorized_accounts?.length === 0) {
              onLoginDirect(config, res?.own_account);
            } else {
              setBeforeLogginStatus(res);
              setBeforeLogginInfo(config);

            }
          }
        } else {
          setDisplayDanger("Eposta veya parola hatalı.");

        }

      }).catch((err) => {
        setDisplayDanger("Eposta veya parola hatalı.");
      });

    })


  };

  const onBeforeLoginCheck = () => {

    const config = {

      grant_type: "password",
      scope: "",
      client_id: "",
      client_secret: "",
      username: email,
      password: password

    };
    let checkData = {
      email: email,
      code: otp
    };
    beforeLoginCheck(checkData).then(res => {
      if (res.status === "code_match") {
        if (res?.authorized_accounts?.length === 0) {
          onLoginDirect(config, res?.own_account);
        } else {
          setBeforeLogginStatus(res);
          setBeforeLogginInfo(config);
          setOpenTfaView(null);
        }
      } else {
        setDisplayDanger("Hatalı aktivasyon kodu.");
        if (res.status === "you_are_banned" || res.status === "account_blocked") {
          setLoginBanCount(res);
        } else {
          setLoginBanCount(5 - res.error_count);
        }
      }
    });

  };

  const onLogin = (onSubmit) => {
    let loginInfo = { ...beforeLogginInfo };
    loginInfo.client_secret = onSubmit.id;

    login(loginInfo).then((data) => {
      if (data.access_token) {
        setIsLoggin(true);
        setUserInformations({ access_token: data?.access_token, customer_info: data?.customer_info });
        setBeforeLogginInfo({});
        setBeforeLogginStatus(false);
        localStorage.setItem("u_id", data?.customer_info?.customer_code);
        localStorage.setItem("__u_is_resp", data?.is_responsible);
        if (data.permissions && data?.is_responsible) {
          localStorage.setItem("__u_per", encryptMessage(data?.permissions));
          setPermissions(data?.permissions);
        }
        getBasketCount().then(data => {
          setBasketCount(data.count);
        }).catch(err => {
          setBasketCount(0);
        });
        setLogginOpen(false);
      }
    })
      .catch((err) => {
        setDisplayDanger(true);
      });
  };


  const onLoginDirect = (config, ownData) => {
    config.client_secret = ownData.id;
    login(config).then((data) => {
      if (data.access_token) {
        setIsLoggin(true);
        setUserInformations({ access_token: data?.access_token, customer_info: data?.customer_info });
        localStorage.setItem("u_id", data?.customer_info?.customer_code);
        localStorage.setItem("__u_is_resp", data?.is_responsible);
        if (data.permissions && data?.is_responsible) {
          localStorage.setItem("__u_per", encryptMessage(data?.permissions));
          setPermissions(data?.permissions);
        }
        getBasketCount().then(data => {
          setBasketCount(data.count);
        }).catch(err => {
          setBasketCount(0);
        });

        setLogginOpen(false);

      }
    })
      .catch((err) => {
        setDisplayDanger(true);
      });
  };

  const onGetCountry = async () => {
    let countries = [];
    countries = await getCountry();
    setPhoneList(countries, "phone_code", "country_name");

  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLogginOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onSendPasswordCode = () => {
    if (email !== "" && phone !== "") {
      forgotPasswordSendCode(email, selectedPhone.id, phone, isSendMail == "1" ? "mail" : "sms").then(res => {
        if (res.status === "code_sent") {
          setOpenNewPasswordModal(email);
        } else if (res.status === "check_info") {
          alertToast("Hata", "Bilgilerinizi kontrol ediniz.");
        } else {
          alertToast("Hata", "Bir sorun oluştu");
        }

      }).catch(err => {
        alertToast("Hata", "Bir sorun oluştu");
      });
    } else {
      setDisplayDanger(true);
    }

  };


  useEffect(() => {
    phoneList.map(item => {
      if (item.id === "90") {
        setSelectedPhone(item);
      }
    });
  }, [phoneList]);

  useEffect(() => {
    onGetCountry();
  }, []);
  return (
    <div ref={windowSize.width > 800 ? wrapperRef : null}
      className="relative flex flex-col justify-center px-4 py-6 bg-white rounded-md w-96 lg:px-6 lg:shadow-md lg:border-t-4 lg:border-makdosYellow">
      <svg
        className="absolute hidden right-16 -top-3 lg:block "
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8H1.00921C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z"
          fill="#FFDF0F"
        />
      </svg>
      {!beforeLogginStatus && !passwordReset && !openTfaView &&
        < div className="mx-auto ">
          <h2 className="text-2xl font-medium text-selectboxText">{t("common:signIn")}</h2>
          <p className="mt-3 text-cardText">{t("common:signInDescription")}</p>
          {displayDanger && (
            <div className="mt-2">
              <AlertDanger message={displayDanger} />
            </div>
          )}
          <form onSubmit={handleSubmit(onBeforeLogin)} className="mt-5">
            <div className="mb-4">
              <SimpleTextBox
                innerRef={register}
                errors={errors}
                labelName={t("common:e-mail")}
                inputName="email"
                placeholder={t("common:emailPlaceholder")}
                value={email}
                onChange={(e) => setEmail(e?.toLowerCase())}

              />
              <p className="text-sm text-discountRed">
                {errors.email && t("common:emailError")}
              </p>
            </div>

            <IconTextBox
              innerRef={register}
              errors={errors}
              labelName={t("common:password")}
              inputName="password"
              type="password"
              placeholder="******"
              iconChange={true}
              textBoxIcon="ri-eye-line"
              textBoxSecondIcon="ri-eye-off-line"
              value={password}
              onChange={(e) => setPassword(e)}

            />
            <p className="text-sm text-discountRed">
              {errors.password && t("common:passwordError")}
            </p>

            <div onClick={() => setPasswordReset(true)}
              className="mt-3 cursor-pointer text-textGray">{t("common:forgotPassword")}</div>
            <div className="mt-5 lg:flex lg:flex-row-reverse">
              <Button
                name="signIn"
                buttonText={t("common:signIn")}
                buttonLeftIcon="ri-login-box-line"
                type="submit"
              />
              <div className="mt-3 lg:mr-3 lg:mt-0">
                <Link href={router.pathname == "/configuration" ? `/signup?redirect=${t("nav:configuration")}` : "/signup"} as={router.pathname == "/configuration" ? t("nav:signupquery") + t("nav:configuration") : t("nav:signup")} >
                  <SecondaryButton
                    name="signUp"
                    buttonText={t("common:signUp")}
                    buttonLeftIcon="ri-user-line"
                    type="button"
                    onClick={() => setLogginOpen(false)}
                  />
                </Link>
              </div>
            </div>
          </form>
        </div>
      }
      {beforeLogginStatus && !passwordReset && !openTfaView &&
        <div className="w-full mx-auto ">
          <div onClick={() => onLogin(beforeLogginStatus?.own_account)}
            className="w-full px-4 py-3 rounded-md cursor-pointer bg-makdosYellow">
            <div className="flex items-end">
              <i className="text-lg ri-login-box-line text-titleColor"></i>
              <p className="ml-2 font-medium text-titleColor">{t("common:normalSignIn")}</p>
            </div>
            <p className="mt-1 text-sm text-cardText">{t("common:ownPanelSignIn")}</p>
          </div>
          <div className="h-0.5 w-full bg-gray-200 my-4"></div>
          <p className="mb-2 text-xl font-semibold text-selectboxText">{t("common:authorizedAccounts")}</p>
          {beforeLogginStatus?.authorized_accounts?.map((item, index) => {
            return (
              <div
                className="w-full p-2 transition-all duration-200 rounded-md cursor-pointer text-titleColor hover:bg-makdosSecondaryGray"
                key={index} onClick={() => {
                  onLogin(item);
                }}>
                <p>{item?.customer_name}</p>
              </div>
              // <AuthorizeSignInCard name={item?.customer_name} key={index} onClick={() => { onLogin(item) }} />
            );
          })
          }
        </div>
      }

      {!beforeLogginStatus && passwordReset && !openTfaView &&
        <div className="w-full mx-auto">

          <h2 className="text-2xl font-medium text-selectboxText">{t("common:resetPassword")}</h2>
          <p className="mt-3 text-cardText">{t("common:resetPasswordDescription")}</p>
          {displayDanger && (
            <div className="mt-2">
              <AlertDanger message={t("common:emptyFieldAlert")} />
            </div>
          )}

          <div className="mt-5">
            <SimpleTextBox value={email} onChange={(v) => setEmail(v?.toLowerCase())} labelName={t("common:e-mail")}
              inputName="e-posta" placeholder={t("common:emailPlaceholder")} />

          </div>

          <div className="flex space-x-2">
            {selectedPhone &&
              <div className="mt-5 md:mt-0">
                <PhoneSelectBox data={phoneList} selectedItem={selectedPhone}
                  setSelectedItem={(v) => setSelectedPhone(v)} labelName={t("common:code")} />
              </div>
            }
            <SimpleTextBox value={phone} onChange={(v) => setPhone(v?.replaceAll(/\D/g, ''))} labelName={t("common:phoneNumber")}
              inputName="phone" placeholder="5555555" />
          </div>

          <p className="mt-3 text-cardText">{t("common:verificationMethod")}</p>
          <div className="flex mt-6 mb-6">
            <div>
              <input type="radio" id={"newpass1"} name={"newPass"} onChange={() => setIsSendMail("1")}
                checked={isSendMail == "1"} />
              <label htmlFor={"newpass1"}>{t("common:e-mail")}</label>
            </div>

            {selectedPhone?.id === "90" &&
              <div className="ml-10">
                <input type="radio" id={"newpass2"} name={"newPass"} onChange={() => setIsSendMail("2")}
                  checked={isSendMail == "2"} />
                <label htmlFor={"newpass2"}>Sms</label>
              </div>
            }

          </div>


          <Button style="mt-4" onClick={() => onSendPasswordCode()} name="personelButton" buttonText={t("common:send")}
            buttonLeftIcon="ri-checkbox-circle-line" />


        </div>
      }


      {openTfaView &&
        <div className="w-full mx-auto">
          <p
            className="text-2xl font-medium text-selectboxText">{openTfaView.code_type === "tfa_mail" ? t("common:emailActivation") : t("common:smsActivation")}</p>

          {displayDanger && (
            <div className="mt-2">
              <AlertDanger message={displayDanger} />
            </div>
          )}
          <p
            className="mt-3 text-cardText">{openTfaView.code_type === "tfa_mail" ? t("common:emailCodeEnter") : t("common:smsActivation")}</p>
          <div className="flex items-center justify-center w-full my-5 ml-2">

            <Validation
              value={otp}
              onChange={(v) => setOtp(v.toLocaleUpperCase("tr"))}
              autoFocus
              inputClassName={
                "text-gray-500 border border-textGray  focus:border-1 focus:border-makdosYellow focus:ring-1 focus:ring-makdosYellow focus:outline-none"
              }
              OTPLength={6}
              otpType="string"
              disabled={false}
              secure
            />

          </div>


          {loginBanCount && loginBanCount < 5 && loginBanCount?.status !== "you_are_banned" && loginBanCount?.status !== "account_blocked" ?
            <div className="mt-3">
              {t("common:remainingEntry")} : {loginBanCount}
            </div>
            :
            (loginBanCount.status === "you_are_banned" || loginBanCount.status === "account_blocked" ?
              <div className="mt-3">
                {moment(loginBanCount?.ban_end_time).format("DD-MM-YYYY HH:mm")} {t("common:bannedUntil")}.
              </div>
              :
              <div></div>

            )
          }
          <Button style="mt-4" onClick={() => onBeforeLoginCheck()} name="checkLoginButton"
            buttonText={t("common:send")} buttonLeftIcon="ri-checkbox-circle-line" />

        </div>

      }
    </div>
  );
}

export default SignIn;
